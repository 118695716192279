html {
	overflow-y: scroll;
}

a {
	color: #8b0000;
}

a:hover {
	color: #8b0000;
}

#navBar {
	padding: 1rem 0;
	background-color: #8b0000;
	padding-left: 1.0rem;
	padding-right: 1.0rem;
}

#updateList {
	list-style: none;
	padding: 4rem 0;
	margin: 2.5rem 0;
}

#updateList li {
	padding-bottom: 2rem;
	font-size: 1.5rem;
	line-height: 1.8rem;
}

#recentUpdateList {
	list-style: none;
	padding: 0;
	margin: 2.5rem 0;
}

#recentUpdateList li {
	padding-bottom: 2rem;
	font-size: 1.5rem;
	line-height: 1.8rem;
}

article {
	padding: 4rem 0;
	font-size: 1.2rem;
	
}

article h1 {
	margin-top: 3rem;
}

article h2,
h3 {
	margin-top: 2.8rem;
	margin-bottom: 0.8rem;
}

article blockquote {
	margin: 2.0rem 1.5rem;
	border-left: 5px solid #8b0000;
	padding-left: 1.5rem;
}

article ul li{
	margin-bottom: 0.8rem;
}

#dateContainer {
	margin-bottom: 2.5rem;
	text-transform: uppercase;
}

#viewAllUpdatesButton {
	color: #8b0000;
	background-color: #fff;
	border-color: #8b0000;
}

#viewAllUpdatesButton a {
	color: #8b0000;
}

.homeUpdate {
	text-transform: uppercase;
	font-size: 1rem;
}

#pageNotFound{
	padding: 7rem 0;
}

#footerContainer {
	margin: 4.0rem 0;
}

article a {
	color: #8b0000;
	text-decoration: underline;
}

article a:hover {
	color: #8b0000;
	text-decoration: none;
}

.faq dt {
	color:  #900;
	font-weight: normal;
}
	
.faq dd {
	margin: 0 0 1.50rem 0;
}